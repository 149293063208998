<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              md="12"
              cols="24"
            >
              <v-tabs
                v-model="tab"
                show-arrows
              >
                <v-tab v-for="(item, index) in translations" :key="index">
                  <span>{{ item.language }}</span>
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(item, index) in translations" :key="index">
                  <v-col
                    cols="24"
                    md="12"
                  >
                    <v-text-field
                      v-model="item.name"
                      label="Наименование"
                      dense
                      outlined
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-tab-item>
              </v-tabs-items>
            </v-col>

            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import { rules } from '@/helpers/validation'

export default {
  name: 'ModifierGroupTranslationForm',
  props: ['save', 'show', 'cancellation', 'label'],
  data: () => ({
    tab: null,
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
  }),
  computed: {
    ...mapFormHandlers('modifierGroupTranslations/form', [
      'id',
      'translations'
    ]),
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    ...mapActions('modifierGroupTranslations/form', ['resetForm']),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>
