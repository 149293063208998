<template>
  <v-card id="promotion-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="dishes"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <!-- edit -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="updateDialogShow(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <modifier-group-translation-form :label="'Редактирование модификаторной группы'" :cancellation="updateDialogCancel" :show="updateDialog"
                           :save="updateDish"></modifier-group-translation-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import { mdiPencilOutline,mdiEye, mdiEyeOff } from '@mdi/js'
import ModifierGroupTranslationForm from './modifier-group-translation-form.vue'

export default {
  components: { ModifierGroupTranslationForm },
  name: 'ModifierGroupTranslationsList',
  data: () => ({
    updateDialog: false,
    icons: {
      mdiPencilOutline,
      mdiEye,
      mdiEyeOff
    },
  }),
  computed: {
    ...mapFormHandlers('modifierGroupTranslations', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.menuVersion',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('modifierGroupTranslations', {
      dishes: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Имя', value: 'name' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    const { id } = this.$route.params
    this.filters.menuVersion = id
    await this.fetchDishes()
  },
  methods: {
    ...mapActions('modifierGroupTranslations', {
      fetchDishes: 'fetchList',
    }),
    ...mapActions('modifierGroupTranslations/form', ['resetForm', 'fetchById', 'update']),
    updateDialogCancel() {
      this.updateDialog = false
    },
    async updateDialogShow(id) {
      await this.fetchById(id)
      this.updateDialog = true
    },
    async updateDish() {
      await this.update()
      this.updateDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
