<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab>
        <span>Блюда</span>
      </v-tab>
      <v-tab>
        <span>Модификаторные группы</span>
      </v-tab>
      <v-tab>
        <span>Модификаторы</span>
      </v-tab>
      <v-tab>
        <span>Модификаторные схемы</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <dish-translations-list></dish-translations-list>
      </v-tab-item>
      <v-tab-item>
        <modifier-group-translations-list></modifier-group-translations-list>
      </v-tab-item>
      <v-tab-item>
        <modifier-group-details-translations-list></modifier-group-details-translations-list>
      </v-tab-item>
      <v-tab-item>
        <modifier-scheme-list></modifier-scheme-list>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import ModifierSchemeList from '@/views/menu-version/modifier-scheme-translation-list.vue'
import DishTranslationsList from '@/views/menu-version/dish-translations-list.vue'
import ModifierGroupTranslationsList from '@/views/menu-version/modifier-group-translation-list.vue'
import ModifierGroupDetailsTranslationsList
  from '@/views/menu-version/modifier-group-details-translation-list.vue'

export default {
  name: 'MenuVersionTranslationForm',
  components: {
    DishTranslationsList,
    ModifierGroupTranslationsList,
    ModifierGroupDetailsTranslationsList,
    ModifierSchemeList,
  },
  data: () => ({
    tab: null,
  }),
}
</script>
